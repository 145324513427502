const productVideoLinks = [
    'https://www.youtube.com/embed/VrPDxCtsKbY',
    'https://www.youtube.com/embed/xOSlkINDJx8',
    'https://www.youtube.com/embed/K2069EhVX00',
    'https://www.youtube.com/embed/zljfzbSt7u0',
    'https://www.youtube.com/embed/1foAhDwXzGQ',
    'https://www.youtube.com/embed/9XwholaKXp0',
    'https://www.youtube.com/embed/qK_nflU9ZR0',
    'https://www.youtube.com/embed/MnXGr0Zieic',
];

export default productVideoLinks;