const sizeChartS30 = {
    'EN': {
        '51CF501': {
            'Size': 'S30 35x20',
        },
    },
    'ES': {
        '51CF501': {
            'Tamaño': 'S30 35x20',
        },
    },
    'RU': {
        '51CF501': {
            'Размер': 'S30 35x20',
        },
    },
};

export default sizeChartS30