const sizeChart51CF6 = {
    'EN': {
        '51CF601': {
            'Size': 'AT series',
        },
    },
    'ES': {
        '51CF601': {
            'Tamaño': 'Serie AT',
        },
    },
    'RU': {
        '51CF601': {
            'Размер': 'Серия AT',
        },
    },
};

export default sizeChart51CF6;